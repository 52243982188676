import userDarkIcon from "assets/user-darkgray.svg";
import storeFrontDark from "assets/store-front-darkgray.svg";
import staffAccessManagementIcon from "assets/staff-access-management.svg";
import logOutDark from "assets/log-out-darkgray.svg";
import Link from "components/common/Link";
import Image from "next/image";
import {isInvestorStore, isStaff, userOnboardingStatus} from "../../../utils/auth";
// import UserInfoWithIcon from "../../UserInfoWithIcon";
import React from "react";
import UserInfoWithIcon from "../../UserInfoWithIcon";
// import {COOKIES, getCookie} from "../../../utils/cookies";
// import getRandomPastelColor from "../../../utils/getRandomPastelColor";

interface DropdownMenuProps {
  profilePathname: string;
  showOption: boolean;
  onLogoutClick: () => void;
  shopifyStoresCount: number;
  userPhoneNumber: string;
  atleastOneShopifyStoreConnected: boolean;
  className?: string;
  staff:any;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  profilePathname,
  showOption,
  onLogoutClick,
  shopifyStoresCount,
  userPhoneNumber,
  atleastOneShopifyStoreConnected,
  staff,
  className,
}) => {
  if (!showOption) return <></>;

  return (
    <div
      id="manage-account-dropdown"
      className={`absolute bg-white flex flex-col right-0 rounded text-xs w-[300px] shadow-[0px_4px_10px_rgba(0,0,0,0.2)] overflow-hidden ${className}`}
    >
      {
        isStaff() && <div className="px-2 py-3">
          <UserInfoWithIcon {...staff} />
        </div>
      }
      {
        !isStaff() && <Link
          href={`/${profilePathname}`}
          passHref
        >
          <a
            className="flex items-center p-[10px] smScreen:px-[10px] smScreen:py-[15px] border-b border-[#EDEDED] cursor-pointer text-theme_1_grey2 hover:bg-[#F8F8F8] hover:font-[600]"
            id="profile-link"
          >
            <Image
              src={userDarkIcon}
              alt="Edit Profile"
            />
            <span className="ml-[10px] text-theme_1_grey1">
            Clout Profile (+91-{userPhoneNumber})
          </span>
          </a>
        </Link>
      }

      {!userOnboardingStatus()?.length && !isInvestorStore() && !isStaff() && atleastOneShopifyStoreConnected && (
        <>
          <Link 
            href={"/store-manager"} 
            passHref
          >
            <a
              className="flex items-center p-[10px] smScreen:px-[10px] smScreen:py-[15px] border-b border-[#EDEDED] hover:bg-[#F8F8F8] hover:font-[600]"
              id="store-manager-link"
            >
              <Image 
                src={storeFrontDark}
                width={"16px"} 
                alt="store-manager" 
              />
              <div className="flex justify-between items-center flex-1 ml-[10px] text-theme_1_grey1">
                <span>Shopify Store Manager</span>
                <span className="text-theme_1_grey2 text-[10px]">
                  {shopifyStoresCount} Store(s)
                </span>
              </div>
            </a>
          </Link>
        </>
      )}
      {
        (!userOnboardingStatus()?.length && !isInvestorStore() && !isStaff() && shopifyStoresCount > 0) && <>
          <Link
            href={"/staff-access-management"}
            passHref
          >
            <a
              className="flex items-center p-[10px] border-b border-[#EDEDED] hover:bg-[#F8F8F8] hover:font-[600]"
              id="staff-access-management-link"
            >
              <Image
                src={staffAccessManagementIcon}
                width={"16px"}
                alt="staff-access-management"
              />
              <div className="flex justify-between items-center flex-1 ml-[10px] text-theme_1_grey1">
                <span>Staff Access Management</span>
              </div>
            </a>
          </Link>
        </>
      }

      <div
        id="logout-button"
        className="flex items-center p-[10px] smScreen:px-[10px] smScreen:py-[15px] cursor-pointer hover:bg-[#F8F8F8] hover:font-[600]"
        onClick={onLogoutClick}
      >
        <Image 
          src={logOutDark} 
          alt="Logout" 
        />
        <span className="ml-[10px] text-theme_1_grey1">Logout</span>
      </div>
    </div>
  );
};

export default DropdownMenu;
